/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 40px)',
  },
  img: {
    marginBottom: -8,
    width: 100,
    maxWidth: '100%',
  },
  p: {
    textAlign: 'center',
  },
  load: {
    fontSize: 24,
    fontFamily: 'sans-serif',
  },
  Parisienne: {
    fontFamily: 'Parisienne, sans-serif',
    fontSize: 30,
  },
  Kalam: {
    fontFamily: 'Kalam, sans-serif',
    fontSize: 25,
  },
}));

const SplashPage = ({ id, darkMode, className, label }) => {
  const classes = useStyles();
  const currDate = new Date();
  const currHour = currDate.getHours();
  let greeting;
  if (currHour < 4) {
    greeting = 'Night';
  } else if (currHour < 12) {
    greeting = 'Morning';
  } else if (currHour < 17) {
    greeting = 'Afternoon';
  } else {
    greeting = 'Evening';
  }

  return (
    <main
      id={id}
      className={ClassNames(classes.root, 'page-transition fade-in')}
    >
      <div className='sp-div'>
        <p className='sp-p'>
          <img
            className={classes.img}
            src={darkMode ? 'img/pebbles_dark.png' : 'img/pebbles_light.png'}
            alt='Daily Pebbles'
          />
        </p>
        <Typography
          variant='h4'
          color='textPrimary'
          className={classes.Parisienne}
          style={{ fontSize: 38 }}
        >
          Daily Pebbles
        </Typography>
        <p className={classes.p}>
          <font className={ClassNames(classes.load, classes.Kalam)}>
            <strong style={{ color: darkMode ? '#eee' : '#111' }}>
              Good {greeting}
            </strong>
          </font>
        </p>
        <p className='sp-p'>
          <font className={ClassNames(classes.load, classes.Kalam)}>
            <i style={{ color: darkMode ? '#eee' : '#111' }}>{label}</i>
          </font>
        </p>
      </div>
    </main>
  );
};

SplashPage.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
};

SplashPage.defaultProps = {
  id: '',
  className: '',
  label: 'Loading...',
};

export default SplashPage;
