/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import PullToRefresh from 'react-simple-pull-to-refresh';

import 'swiper/swiper-bundle.min.css';
import 'vendors/swiper/custom-swiper-bundle.css';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
// import 'swiper/components/scrollbar/scrollbar.scss';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Select from '@material-ui/core/Select';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import Typography from '@material-ui/core/Typography';

import { useLocalState } from 'hooks/useStorageState';
import { doGetDaysQuotes } from 'vendors/firebase/fireauth';
import NamedDivider from 'components/NamedDivider';
import SplashPage from 'views/pages/Splash';

import { BRIGHTNESS, MAX_QUOTES_PER_PAGE } from 'constants/general';

SwiperCore.use([Navigation, Pagination]);

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 40px)',
  },
  divider: {
    background: '#787878',
  },
  slide: {
    height:
      '100vh' /* Use vh as a fallback for browsers that do not support Custom Properties */,
    margin: '0 auto',
    // width: '100vw',
    display: 'grid',
    textAlign: 'center',
    transition: 'all 1000s ease',
  },
  quote: {
    position: 'relative',
    // height: 'calc(100% - 72px)',
    width: '100vw',
    padding: '60px 30px 60px',
    textAlign: 'center',
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minWidth: '90vw',
    margin: 0,
    transform: 'translate(-50%, -42%)',
  },
  fontAbout: {
    fontFamily: "'Cormorant SC', serif",
    // fontFamily: 'Parisienne, sans-serif',
  },
  Lobster: {
    fontFamily: "'Lobster Two', cursive",
  },
  PTMono: {
    fontFamily: "'PT Mono', monospace",
  },
  Qwigley: {
    fontFamily: "'Qwigley', cursive",
  },
  Shadows: {
    fontFamily: "'Shadows Into Light', cursive",
  },
  Stint: {
    fontFamily: "'Stint Ultra Expanded', cursive",
  },
  Courgette: {
    fontFamily: 'Courgette, cursive',
  },
  JustMe: {
    fontFamily: "'Just Me Again Down Here', sans-serif",
  },
  Kalam: {
    fontFamily: 'Kalam, sans-serif',
  },
  Parisienne: {
    fontFamily: 'Parisienne, sans-serif',
  },
  Patrick: {
    fontFamily: "'Patrick Hand', sans-serif",
  },
  SansSerif: {
    fontFamily: 'sans-serif',
  },
  refreshList: {
    marginBlockEnd: 0,
    marginBlockStart: 0,
    paddingInlineStart: 0,
    '& > li': {
      listStyleType: 'none',
    },
  },
  refreshDiv: {
    height: 0,
    transition: 'all 2s',
    opacity: 0,
    width: '100%',
    pointerEvents: 'none',
  },
  refreshAvailable: {
    opacity: 1,
  },
  pullDownDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'italic',
    fontSize: 14,
    paddingTop: 2,
  },
  icon: {
    opacity: '0.4',
  },
  controls: {
    position: 'absolute',
    top: 5,
    zIndex: '999',
    cursor: 'pointer',
  },
  list: {
    width: 250,
    height: '100%',
  },
  brightness: {
    fontSize: '1.7142857142857142rem',
    width: '1em',
    height: '1em',
    color: 'inherit',
  },
  link: {
    textDecoration: 'none',
  },
  robosoLogo: {
    opacity: 0,
    transition: 'opacity 1s',
  },
  robosoText: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
    opacity: 1,
    transition: 'opacity 1s',
  },
  settingsGridContainer: {
    display: 'grid',
    gridGap: 20,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'auto auto auto',
      gridGap: 10,
    },
    [theme.breakpoints.up('md')]: {
      gridGap: 50,
    },
  },
  settingsTest: {
    paddingTop: 50,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 100,
    },
  },
}));

const FONT_MAX = {
  Courgette: { sm: 22, md: 30, lg: 42 },
  JustMe: { sm: 30, md: 42, lg: 54 },
  Kalam: { sm: 22, md: 32, lg: 46 },
  Lobster: { sm: 24, md: 38, lg: 52 },
  Parisienne: { sm: 26, md: 38, lg: 52 },
  Patrick: { sm: 24, md: 38, lg: 52 },
  PTMono: { sm: 20, md: 28, lg: 38 },
  Qwigley: { sm: 36, md: 54, lg: 72 },
  SansSerif: { sm: 20, md: 29, lg: 39 },
  Shadows: { sm: 24, md: 38, lg: 52 },
  Stint: { sm: 20, md: 28, lg: 38 },
};

const Landing = React.forwardRef(
  ({ id, darkMode, brightness, onBrightnessChange }, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let _interval = null;
    const [greeting, setGreeting] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading...');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currStoredQuotes, setCurrStoredQuotes] = useLocalState(
      [],
      'curr-quotes'
    );
    const [nextStoredQuotes, setNextStoredQuotes] = useLocalState(
      [],
      'next-quotes'
    );
    const [storedIgnoreList, setStoredIgnoreList] = useLocalState([], 'ignore');
    const [currStoredDay, setCurrStoredDay] = useLocalState(0, 'day');
    const [isDayChange, setIsDayChange] = useState(false);
    const [isNewDay, setIsNewDay] = useState(false);
    const [page, setPage] = useState('main');

    const [lastTimeCheck, setLastTimeCheck] = useState('');
    const [swiperRef, setSwiperRef] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeChange, setActiveChange] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [font, setFont] = useLocalState('Kalam', 'font');
    const [fontSize, setFontSize] = useLocalState('md', 'size');
    const [fontSizeMax, setFontSizeMax] = useLocalState(
      FONT_MAX['Kalam']['md'],
      'max'
    );
    const [loadingRobosoLogo, setLoadingRobosoLogo] = React.useState(true);

    useEffect(() => {
      window.sessionStorage.removeItem('pos');
      window.sessionStorage.removeItem('quotes');
      window.sessionStorage.removeItem('debug-day');
      resizeQuotes();
      // eslint-disable-next-line
    }, []);

    const fontClass = ClassNames(classes[font]);

    const resetToDefault = () => {
      setFont('Kalam');
      setFontSize('md');
      setFontSizeMax(FONT_MAX['Kalam']['md']);
    };

    const isOverflown = (
      clientHeight,
      viewportHeight,
      clientWidth,
      viewportWidth
    ) => clientHeight > viewportHeight || clientWidth > viewportWidth;

    const resizeText = (parent, child, author) => {
      const minSize = 10;
      const step = 0.5;
      const maxSize = fontSizeMax + step;
      const unit = 'px';

      let i = minSize;
      let overflow = false;

      let intViewportHeight = window.innerHeight;
      let intViewportWidth = window.innerWidth;

      let count = 100;
      while (!overflow && i < maxSize && count > 0) {
        count = count - 1;
        child.style.fontSize = `${i}${unit}`;
        if (author) {
          author.style.fontSize = `${i * 0.6}${unit}`;
        }
        overflow = isOverflown(
          parent.clientHeight,
          intViewportHeight,
          parent.clientWidth,
          intViewportWidth
        );
        if (!overflow) i += step;
      }
      const newFont = i - step;
      child.style.fontSize = `${newFont}${unit}`;
      if (author) {
        author.style.fontSize = `${newFont * 0.6}${unit}`;
      }
      setTriggerRefresh((prev) => !prev);
    };

    const resizeQuotes = () => {
      if (currStoredQuotes.length === 0 || activeIndex > 4) return;
      let parent;
      let child;
      let author = null;
      parent = document.getElementById(
        `parent-${currStoredQuotes[activeIndex].id}`
      );
      child = document.getElementById(
        `child-${currStoredQuotes[activeIndex].id}`
      );
      if (currStoredQuotes[activeIndex].author) {
        author = document.getElementById(
          `author-${currStoredQuotes[activeIndex].id}`
        );
      }
      if (parent && child) {
        resizeText(parent, child, author);
      }
    };

    useEffect(() => {
      resizeQuotes();
      // eslint-disable-next-line
    }, [activeIndex, activeChange, font, fontSizeMax]);

    useLayoutEffect(() => {
      const resize = () => {
        setActiveChange((prev) => !prev);
      };
      window.addEventListener('resize', resize);
      return () => window.removeEventListener('resize', resize);
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      setStoredIgnoreList((prev) => {
        const newIgnoreItems = [];
        currStoredQuotes.forEach((q) => {
          if (!prev.includes(q.id)) {
            newIgnoreItems.push(q.id);
          }
        });
        nextStoredQuotes.forEach((q) => {
          if (!prev.includes(q.id)) {
            newIgnoreItems.push(q.id);
          }
        });
        return prev.concat(newIgnoreItems).slice(-(MAX_QUOTES_PER_PAGE * 30));
      });
      // eslint-disable-next-line
    }, [currStoredQuotes, nextStoredQuotes]);

    const setNewCurrStoredQuotes = (quotes) => {
      const currEndDate = new Date();
      currEndDate.setHours(23, 59, 59, 999);
      const currEndDateMs = currEndDate.getTime();
      setCurrStoredQuotes(quotes);
      setCurrStoredDay(currEndDateMs);
      setIsNewDay(false);
    };

    const getMoreQuotes = async () =>
      // console.log('getMoreQuotes:', storedIgnoreList.join());
      doGetDaysQuotes(storedIgnoreList)
        .then(({ data }) => {
          if (data && data.length > 0) {
            if (currStoredQuotes.length === 0) {
              setNewCurrStoredQuotes(data.slice(0, MAX_QUOTES_PER_PAGE));
              setNextStoredQuotes((prev) =>
                prev.concat(data.slice(MAX_QUOTES_PER_PAGE))
              );
              resizeQuotes();
            } else {
              setNextStoredQuotes((prev) => prev.concat(data));
            }
          }
          setLoading(false);
          setIsNewDay(false);
        })
        .catch((err) => {
          // TODO: schedule next attempt.
          console.log('Landing error:', err);
          setLoading(false);
          setIsNewDay(false);
        });

    const updateQuotes = async () => {
      if (nextStoredQuotes.length >= MAX_QUOTES_PER_PAGE) {
        const needsMoreQuotes =
          nextStoredQuotes.length <= MAX_QUOTES_PER_PAGE * 2;
        setNewCurrStoredQuotes(nextStoredQuotes.slice(0, MAX_QUOTES_PER_PAGE));
        if (swiperRef) {
          swiperRef.slideTo(0, 0, false);
        }
        setNextStoredQuotes((prev) => {
          return prev.slice(MAX_QUOTES_PER_PAGE);
        });
        if (needsMoreQuotes) {
          await getMoreQuotes();
        }
      } else {
        await getMoreQuotes();
      }
      // setCanRefresh(false);
      return Promise.resolve('ok');
    };

    const checkCurrentTime = async () => {
      const currDateTime = new Date();
      const dateStr = currDateTime.toLocaleString();
      setLastTimeCheck(dateStr);
      // setAlarmClock(new Date());
      // currEndDate.setHours(23, 59, 59, 999);
      // setLastTimeCheck(currEndDate);
      const currDateTimeMs = currDateTime.getTime();
      return currDateTimeMs > currStoredDay;
    };

    const setNewDayCheckTimer = () => {
      if (_interval) {
        clearInterval(_interval);
      }
      let lCurrStoredDay = currStoredDay;
      _interval = setInterval(async () => {
        const currDateTime = new Date();
        const dateStr = currDateTime.toLocaleString();
        setLastTimeCheck(dateStr);
        const currDateTimeMs = currDateTime.getTime();
        if (currDateTimeMs > lCurrStoredDay) {
          setIsDayChange((prev) => !prev);
          currDateTime.setHours(23, 59, 59, 999);
          lCurrStoredDay = currDateTime.getTime();
        }
      }, 5000);
    };

    useEffect(() => {
      const currDateTime = new Date();
      const currDateTimeMs = currDateTime.getTime();
      if (currStoredDay > 0 && currDateTimeMs > currStoredDay) {
        setIsNewDay(true);
      }
      // eslint-disable-next-line
    }, [isDayChange]);

    const checkIfUpdateNeeded = () => {
      setLoading(true);
      setActiveIndex(0);
      setTimeout(() => {
        if (nextStoredQuotes.length >= MAX_QUOTES_PER_PAGE) {
          const needsMoreQuotes =
            nextStoredQuotes.length <= MAX_QUOTES_PER_PAGE * 2;
          setNewCurrStoredQuotes(
            nextStoredQuotes.slice(0, MAX_QUOTES_PER_PAGE)
          );
          setIsNewDay(false);
          setLoading(false);
          setNextStoredQuotes((prev) => prev.slice(MAX_QUOTES_PER_PAGE));
          if (needsMoreQuotes) {
            getMoreQuotes();
          }
        } else {
          getMoreQuotes();
        }
      }, 500);
    };

    useEffect(() => {
      const initializeQuotes = async () => {
        setLoading(true);
        if (currStoredQuotes.length === 0) {
          getMoreQuotes();
        } else {
          const newDay = await checkCurrentTime();
          if (newDay) {
            await updateQuotes();
            setLoading(false);
          } else {
            const needsMoreQuotes =
              nextStoredQuotes.length < MAX_QUOTES_PER_PAGE * 2;
            if (needsMoreQuotes) {
              getMoreQuotes();
            } else {
              setLoading(false);
            }
          }
        }
        // setLoading(false);
        setLoadingText('Updating...');
        setNewDayCheckTimer();
      };
      initializeQuotes();
      setTimeout(() => {
        setGreeting(false);
      }, 1500);
      return () => {
        if (_interval) {
          clearInterval(_interval);
        }
      };
      // eslint-disable-next-line
    }, []);

    const handleBrightnessChange = (newBrightness) =>
      onBrightnessChange(newBrightness);

    const toggleDrawer = (open) => (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

    const onFontChange = (event) => {
      const newFont = event.target.value;
      setFont(newFont);
      setFontSizeMax(FONT_MAX[newFont][fontSize]);
      resizeQuotes();
    };

    if (page === 'settings') {
      return (
        <main
          id={id}
          ref={ref}
          className={classes.root}
          style={{ padding: 20 }}
        >
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <Button
              variant='outlined'
              // color='inherit'
              startIcon={<ChevronLeftIcon />}
              size='small'
              onClick={() => setPage('main')}
              style={{ minWidth: 84 }}
            >
              Back
            </Button>
            <Typography
              variant='h6'
              color='textPrimary'
              align='center'
              style={{ marginTop: 5, marginRight: 76, width: '100%' }}
            >
              Settings
            </Typography>
          </div>
          <NamedDivider
            name='Quotation text style'
            color='tertiary'
            size='md'
            lineWidth={3}
            style={{ paddingBottom: 10 }}
          />
          <div className={classes.settingsGridContainer}>
            <FormControl variant='outlined' style={{ marginTop: 18 }}>
              <InputLabel id='font-select-label'>Select a Font</InputLabel>
              <Select
                labelId='font-select-label'
                id='font-select'
                value={font}
                onChange={onFontChange}
                label='Select a Font'
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem value='Courgette'>Courgette</MenuItem>
                <MenuItem value='JustMe'>Just Me Again Down Here</MenuItem>
                <MenuItem value='Kalam'>Kalam</MenuItem>
                <MenuItem value='Lobster'>Lobster</MenuItem>
                <MenuItem value='Parisienne'>Parisienne</MenuItem>
                <MenuItem value='Patrick'>Patrick Hand</MenuItem>
                <MenuItem value='PTMono'>PT Mono</MenuItem>
                <MenuItem value='Qwigley'>Qwigley</MenuItem>
                <MenuItem value='SansSerif'>Sans-serif</MenuItem>
                <MenuItem value='Shadows'>Shadows</MenuItem>
                <MenuItem value='Stint'>Stint</MenuItem>
              </Select>
            </FormControl>
            <div>
              <Typography
                variant='caption'
                color='textPrimary'
                style={{
                  display: 'block',
                  paddingLeft: 15,
                }}
              >
                Max Font Size
              </Typography>
              <ButtonGroup
                color='primary'
                aria-label='outlined primary button group'
                style={{ width: '100%' }}
              >
                <Button
                  variant={fontSize === 'sm' ? 'contained' : 'outlined'}
                  style={{ textTransform: 'initial', width: '100%' }}
                  onClick={() => {
                    setFontSize('sm');
                    setFontSizeMax(FONT_MAX[font]['sm']);
                  }}
                >
                  <span style={{ fontSize: 14 }}>Small</span>
                </Button>
                <Button
                  variant={fontSize === 'md' ? 'contained' : 'outlined'}
                  style={{ textTransform: 'initial', width: '100%' }}
                  onClick={() => {
                    setFontSize('md');
                    setFontSizeMax(FONT_MAX[font]['md']);
                  }}
                >
                  <span style={{ fontSize: 18 }}>Default</span>
                </Button>
                <Button
                  variant={fontSize === 'lg' ? 'contained' : 'outlined'}
                  style={{ textTransform: 'initial', width: '100%' }}
                  onClick={() => {
                    setFontSize('lg');
                    setFontSizeMax(FONT_MAX[font]['lg']);
                  }}
                >
                  <span style={{ fontSize: 24 }}>Large</span>
                </Button>
              </ButtonGroup>
            </div>
            <Hidden xsDown>
              <Button
                variant='outlined'
                onClick={resetToDefault}
                style={{ maxWidth: 200, maxHeight: 54, marginTop: 18 }}
              >
                {`Reset${smallScreen ? '' : ' to Default'}`}
              </Button>
            </Hidden>
          </div>
          <div id='parent-settings' className={classes.settingsTest}>
            <Typography
              id='quote-settings'
              variant='h6'
              color='textPrimary'
              className={fontClass}
              style={{
                color: darkMode ? '#eee' : '#111',
                fontSize: fontSizeMax,
                paddingBottom: 0,
              }}
            >
              <strong>This is what your quotation text will look like.</strong>
            </Typography>
            <Typography
              id='author-settings'
              variant='body1'
              color='textPrimary'
              className={fontClass}
              style={{
                color: darkMode ? '#aaa' : '#777',
                fontSize: fontSizeMax * 0.6,
                paddingBottom: 10,
                marginLeft: 80,
              }}
            >
              ~ Author Name
            </Typography>
          </div>
          <Hidden smUp>
            <Button
              variant='outlined'
              onClick={resetToDefault}
              style={{ width: '100%', maxHeight: 54, marginTop: 18 }}
            >
              Reset to Default
            </Button>
          </Hidden>
        </main>
      );
    }

    if (page === 'about') {
      return (
        <main
          id={id}
          ref={ref}
          className={classes.root}
          style={{ padding: 10 }}
        >
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <Button
              variant='outlined'
              startIcon={<ChevronLeftIcon />}
              size='small'
              onClick={() => setPage('main')}
              style={{ minWidth: 84 }}
            >
              Back
            </Button>
            <Typography
              variant='h6'
              color='textPrimary'
              align='center'
              style={{ marginTop: 5, marginRight: 76, width: '100%' }}
            >
              About
            </Typography>
          </div>
          <div
            className={classes.center}
            style={{
              textAlign: 'center',
            }}
          >
            <img
              style={{ width: 100, maxWidth: '100%' }}
              src={darkMode ? 'img/pebbles_dark.png' : 'img/pebbles_light.png'}
              alt='Roboso Pebbles Quotes'
            />
            <Typography
              variant='h4'
              color='textPrimary'
              className={classes.Parisienne}
              style={{ fontSize: 38 }}
            >
              Daily Pebbles
            </Typography>
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.fontAbout}
              style={{ fontSize: 16, textTransform: 'none', paddingBottom: 20 }}
            >
              App Version 1.0.0
            </Typography>
            <Typography
              variant='h6'
              color='textPrimary'
              className={classes.fontAbout}
            >
              Five simple quotes per day{' '}
              <span style={{ whiteSpace: 'nowrap' }}>to bring inspiration</span>{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                and motivation to your life.
              </span>{' '}
              <br />
              <span style={{ whiteSpace: 'nowrap' }}>
                What you do next is up to you.
              </span>
            </Typography>
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.fontAbout}
              style={{ paddingTop: 50, paddingBottom: 10 }}
            >
              Created by
            </Typography>
            <div style={{ position: 'relative', height: 45.5 }}>
              <Typography
                variant='h5'
                color='textPrimary'
                className={classes.robosoText}
                style={loadingRobosoLogo ? {} : { opacity: '0' }}
              >
                Rock Bottom Software
              </Typography>
              <img
                width={300}
                src={
                  darkMode
                    ? 'img/roboso_full_logo_dark.svg'
                    : 'img/roboso_full_logo_light.svg'
                }
                alt='Rock Bottom Software'
                className={classes.robosoLogo}
                style={!loadingRobosoLogo ? { opacity: '1' } : {}}
                onLoad={() => setLoadingRobosoLogo(false)}
                // onLoad={() => {
                //   setTimeout(() => {
                //     setLoadingRobosoLogo(false);
                //   }, 3000);
                // }}
              />
            </div>
            <Typography
              variant='h6'
              color='textPrimary'
              className={classes.fontAbout}
              style={{ paddingTop: 10 }}
            >
              {/* <span style={{ whiteSpace: 'nowrap' }}>
                Solution is built on
              </span>{' '}
              <span style={{ whiteSpace: 'nowrap' }}>a solid foundation</span> */}
              <span style={{ whiteSpace: 'nowrap' }}>Solutions built on</span>{' '}
              <span style={{ whiteSpace: 'nowrap' }}>a solid foundation</span>
              {/* Solid ground. Peace of mind */}
              {/* Nowhere to go but up */}
            </Typography>
            <a
              href='https://www.rockbottomsoftware.com'
              target='_blank'
              rel='noopener noreferrer'
              className={classes.link}
            >
              <Typography
                variant='body1'
                color='textPrimary'
                className={classes.fontAbout}
              >
                www.rockbottomsoftware.com
              </Typography>
            </a>
          </div>
        </main>
      );
    }

    // if (page === 'login') {
    //   return (
    //     <main
    //       id={id}
    //       ref={ref}
    //       className={classes.root}
    //       style={{ padding: 20 }}
    //     >
    //       <div style={{ display: 'flex' }}>
    //         <Button
    //           variant='outlined'
    //           size='small'
    //           onClick={() => setPage('main')}
    //         >
    //           Cancel
    //         </Button>
    //         <Typography
    //           variant='h6'
    //           color='textPrimary'
    //           gutterBottom
    //           style={{ paddingLeft: 20 }}
    //         >
    //           Login page.
    //         </Typography>
    //       </div>
    //     </main>
    //   );
    // }

    return (
      <main id={id} ref={ref} className={classes.root}>
        <div
          className={classes.controls}
          style={{ right: 10, padding: 10 }}
          onClick={() => setDrawerOpen(true)}
        >
          <MenuIcon color='primary' className={classes.icon} />
        </div>
        <Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer(false)}>
          <div
            className={classes.list}
            role='presentation'
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List component='nav' aria-labelledby='nested-list-subheader'>
              <ListItem
                key='dark'
                button
                onClick={() => handleBrightnessChange(BRIGHTNESS.DARK)}
              >
                <ListItemIcon className={classes.brightness}>
                  {brightness === BRIGHTNESS.DARK ? (
                    <Brightness4Icon style={{ color: '#00cb0e' }} />
                  ) : (
                    <Brightness4Icon />
                  )}
                </ListItemIcon>
                <ListItemText primary='Dark mode' />
              </ListItem>
              <ListItem
                key='light'
                button
                onClick={() => handleBrightnessChange(BRIGHTNESS.LIGHT)}
              >
                <ListItemIcon className={classes.brightness}>
                  {brightness === BRIGHTNESS.LIGHT ? (
                    <Brightness7Icon style={{ color: '#02b80e' }} />
                  ) : (
                    <Brightness7Icon />
                  )}
                </ListItemIcon>
                <ListItemText primary='Light mode' />
              </ListItem>
              <ListItem
                key='auto'
                button
                onClick={() => handleBrightnessChange(BRIGHTNESS.AUTO)}
              >
                <ListItemIcon className={classes.brightness}>
                  {brightness === BRIGHTNESS.AUTO ? (
                    <BrightnessAutoIcon
                      style={{
                        color:
                          theme.palette.type === 'dark' ? '#00cb0e' : '#02b80e',
                      }}
                    />
                  ) : (
                    <BrightnessAutoIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary='Auto mode' />
              </ListItem>
            </List>
            <Divider className={classes.divider} />
            <List>
              <ListItem
                button
                key='settings'
                onClick={() => setPage('settings')}
              >
                <ListItemIcon style={{ color: 'inherit' }}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary='More settings' />
              </ListItem>
              <ListItem button key='about' onClick={() => setPage('about')}>
                <ListItemIcon style={{ color: 'inherit' }}>
                  <img
                    width={18}
                    height={18}
                    src={
                      darkMode
                        ? 'img/pebbles_icon_dark.svg'
                        : 'img/pebbles_icon_light.svg'
                    }
                    alt='Pebbles logo'
                    style={{ margin: 3 }}
                  />
                </ListItemIcon>
                <ListItemText primary='About' />
              </ListItem>
              {/* <ListItem button key='login' onClick={() => setPage('login')}>
                <ListItemIcon style={{ color: 'inherit' }}>
                  <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText primary='Login' />
              </ListItem> */}
            </List>
          </div>
        </Drawer>
        {greeting || loading ? (
          <SplashPage darkMode={darkMode} label={loadingText} />
        ) : (
          <PullToRefresh
            isPullable={isNewDay}
            onRefresh={checkIfUpdateNeeded}
            className='page-transition fade-in'
          >
            <ul className={classes.refreshList}>
              <li style={{ position: 'relative' }}>
                <div
                  className={ClassNames(classes.refreshDiv, {
                    [classes.refreshAvailable]: isNewDay,
                  })}
                >
                  <Typography
                    variant='h6'
                    color='textPrimary'
                    style={{
                      textAlign: 'center',
                      paddingTop: 10,
                      fontSize: 18,
                    }}
                  >
                    New quotes available
                  </Typography>
                  <Typography
                    variant='caption'
                    color='textPrimary'
                    className={classes.pullDownDiv}
                  >
                    <ExpandMoreIcon />
                    Pull down to refresh
                    <ExpandMoreIcon />
                  </Typography>
                </div>
                <Swiper
                  slidesPerView={1}
                  initialSlide={activeIndex}
                  autoHeight={true}
                  observeParents={true}
                  observeSlideChildren={true}
                  observer={true}
                  pagination={{
                    clickable: false,
                    bulletClass: 'swiper-pagination-bullet-custom',
                    bulletActiveClass: 'swiper-pagination-bullet-active-custom',
                  }}
                  onSwiper={(swiper) => setSwiperRef(swiper)}
                  onAfterInit={resizeQuotes}
                  onActiveIndexChange={(swiper) => {
                    setActiveIndex(swiper.activeIndex);
                  }}
                  style={{
                    height: '100vh',
                  }}
                >
                  {currStoredQuotes.map((q) => (
                    <SwiperSlide key={q.id}>
                      <div
                        style={{
                          height: '100vh',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          id={`parent-${q.id}`}
                          className={ClassNames(classes.quote, fontClass)}
                        >
                          <div
                            id={`child-${q.id}`}
                            style={{
                              color: darkMode ? '#eee' : '#111',
                            }}
                          >
                            {q.quote.split('\n').map((line, index) => (
                              <span key={index}>
                                <strong>{line}</strong>
                                <br />
                              </span>
                            ))}
                            {q.author && (
                              <>
                                <span
                                  id={`author-${q.id}`}
                                  style={{
                                    color: darkMode ? '#aaa' : '#777',
                                  }}
                                >
                                  ~ {q.author}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}

                  <SwiperSlide key='debug'>
                    <div className={classes.slide}>
                      <div
                        className={classes.quote}
                        style={{
                          color: darkMode ? '#eee' : '#111',
                        }}
                      >
                        <div className={classes.center}>
                          <Typography variant='body1' color='inherit'>
                            Curr Quotes:{' '}
                            {currStoredQuotes.map((q) => q.id).join()}
                          </Typography>
                          <Typography variant='body1' color='inherit'>
                            Next Count: {nextStoredQuotes.length}
                          </Typography>
                          <Typography variant='body1' color='inherit'>
                            Next Quotes:
                            <br />
                            {nextStoredQuotes
                              .map((q) => q.id)
                              .slice(0, 10)
                              .join()}
                            <br />
                            {nextStoredQuotes
                              .map((q) => q.id)
                              .slice(10, 20)
                              .join()}
                            <br />
                            {nextStoredQuotes
                              .map((q) => q.id)
                              .slice(20, 30)
                              .join()}
                            <br />
                            {nextStoredQuotes
                              .map((q) => q.id)
                              .slice(30)
                              .join()}
                          </Typography>
                          <Typography
                            variant='body1'
                            color='inherit'
                            style={{ marginTop: 20 }}
                          >
                            Stored Day:{' '}
                            {currStoredDay
                              ? new Date(currStoredDay).toLocaleString()
                              : 'none'}
                          </Typography>
                          <Typography variant='body1' color='inherit'>
                            Curr Day: {lastTimeCheck}
                          </Typography>
                          <Typography
                            variant='body1'
                            color='inherit'
                            style={{ marginTop: 20 }}
                          >
                            Ignore Count: {storedIgnoreList.length}
                          </Typography>
                          <Typography variant='body1' color='inherit'>
                            Ignore Quotes:
                            <br />
                            {storedIgnoreList.slice(0, 10).join()}
                            <br />
                            {storedIgnoreList.slice(10, 20).join()}
                            <br />
                            {storedIgnoreList.slice(20, 30).join()}
                            <br />
                            {storedIgnoreList.slice(30, 40).join()}
                            <br />
                            {storedIgnoreList.slice(40, 50).join()}
                            <br />
                            {storedIgnoreList.slice(50, 60).join()}
                            <br />
                            {storedIgnoreList.slice(60, 70).join()}
                            <br />
                            {storedIgnoreList.slice(70, 80).join()}
                            <br />
                            {storedIgnoreList.slice(80, 90).join()}
                            <br />
                            {storedIgnoreList.slice(90, 100).join()}
                            <br />
                            {storedIgnoreList.slice(100, 110).join()}
                            <br />
                            {storedIgnoreList.slice(110, 120).join()}
                            <br />
                            {storedIgnoreList.slice(120, 130).join()}
                            <br />
                            {storedIgnoreList.slice(130, 140).join()}
                            <br />
                            {storedIgnoreList.slice(140, 150).join()}
                            <br />
                            {storedIgnoreList.slice(150).join()}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </li>
            </ul>
          </PullToRefresh>
        )}
      </main>
    );
  }
);

Landing.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  darkMode: PropTypes.bool.isRequired,
  brightness: PropTypes.number.isRequired,
  onBrightnessChange: PropTypes.func.isRequired,
  id: PropTypes.string,
};

Landing.defaultProps = {
  id: '',
};

export default withRouter(Landing);
