/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */
export const SITEURL =
  process.env.NODE_ENV === 'development'
    ? 'http://www.localhost.com:3000/'
    : 'https://www.amightygoodtime.com/';

export const CLOUDINARY_CLOUDNAME = 'ds6rxxvr3';
export const CLOUDINARY = `https://res.cloudinary.com/${CLOUDINARY_CLOUDNAME}/image/upload/f_auto,q_auto/`;
export const CLOUDINARY_EDIT = `https://res.cloudinary.com/${CLOUDINARY_CLOUDNAME}/image/upload/`;
export const CLOUDINARY_EVENTS = 'sona/events/';
export const CLOUDINARY_ORGANIZERS = 'sona/organizers/';
export const CLOUDINARY_UPLOAD = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload/`;
// TODO: Hide presets from all non-members
export const CLOUDINARY_PRESET = 'as8glwao';
export const CLOUDINARY_ORGANIZER_PRESET = 'rpddpsxx';

export const MAX_QUOTES_PER_PAGE = 5;

export const BRIGHTNESS = {
  DARK: 0,
  LIGHT: 1,
  AUTO: 2,
};
