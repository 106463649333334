/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */
import fireapp from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'roboso-app.firebaseapp.com',
  projectId: 'roboso-app',
  storageBucket: 'roboso-app.appspot.com',
  // messagingSenderId: "147910354015",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

if (!fireapp.apps.length) {
  fireapp.initializeApp(config);
}

const fireauth = fireapp.auth();

const dynamicImports = {};
const getFirestore = async () => {
  if (dynamicImports.firestore) {
    return dynamicImports.firestore;
  }
  return Promise.all([import('firebase/firestore')]).then(() => {
    dynamicImports.firestore = fireapp.firestore();
    return dynamicImports.firestore;
  });
};
const getFireFunctions = async () => {
  if (dynamicImports.firefunc) {
    return dynamicImports.firefunc;
  }
  return Promise.all([import('firebase/functions')]).then(() => {
    dynamicImports.firefunc = fireapp.functions();
    return dynamicImports.firefunc;
  });
};

export {
  fireauth,
  getFirestore,
  getFireFunctions,
  fireapp,
};
