/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

const UserContext = React.createContext();
const { Provider, Consumer } = UserContext;

const UserProvider = ({ children }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Provider
        value={{
          init: false,
        }}
      >
        {children}
      </Provider>
    </Suspense>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  UserContext,
  UserProvider,
  Consumer as UserConsumer,
};
