/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */
import { fireauth, getFireFunctions } from 'vendors/firebase/main';
import { MAX_QUOTES_PER_PAGE } from 'constants/general';
// import * as dbApi from 'vendors/firebase/firestore';

// [START Users]
export const doSignOut = () => fireauth.signOut();

// export const doSignInWithEmailAndPassword = (email, password) =>
//   fireauth.signInWithEmailAndPassword(email, password).then((authData) => {
//     if (authData && !needsEmailVerification(authData.user)) {
//       return Promise.resolve(fireauth.currentUser);
//     }
//     doSignOut();
//     // eslint-disable-next-line prefer-promise-reject-errors
//     return Promise.reject({
//       code: 'auth/unverified-email',
//       message: 'Email address not verified',
//     });
//   });

export const doGetDaysQuotes = async (ignoreList) => {
  const ff = await getFireFunctions();
  // const ignoreList = [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  // const ignoreList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const getDaysQuotes = ff.httpsCallable('getDaysQuotes');
  return getDaysQuotes({
    numOfQuotes: MAX_QUOTES_PER_PAGE * 7,
    ignoreList: ignoreList.join(),
  }).then((result) => result);
};
