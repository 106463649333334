/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */
import React, { useEffect, useState, Suspense } from 'react';
// import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './page-transitions.css';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

// import { UserContext, UserConsumer } from 'UserContext';
import { useLocalState } from 'hooks/useStorageState';
import * as routes from 'constants/routes';

// import ContactUsPage from 'views/pages/ContactUs';
import LandingPage from 'views/pages/Landing';
// import LoginPage from 'views/pages/Login';
import SplashPage from 'views/pages/Splash';

import { BRIGHTNESS } from 'constants/general';

const App = () => {
  const [brightness, setBrightness] = useLocalState(
    BRIGHTNESS.AUTO,
    'brightness'
  );
  const [prefersDarkMode, setPrefersDarkMode] = useState(true);
  const [darkMode, setDarkMode] = useState(
    brightness === BRIGHTNESS.AUTO
      ? prefersDarkMode
      : brightness === BRIGHTNESS.DARK
  );

  useEffect(() => {
    setDarkMode(
      brightness === BRIGHTNESS.AUTO
        ? prefersDarkMode
        : brightness === BRIGHTNESS.DARK
    );
  }, [brightness, prefersDarkMode]);

  useEffect(() => {
    const getQueryPreference = (event) => {
      if (event.matches) {
        setPrefersDarkMode(true);
      } else {
        setPrefersDarkMode(false);
      }
    };
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setPrefersDarkMode(true);
      } else {
        setPrefersDarkMode(false);
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', getQueryPreference);
    }
    return () => window.removeEventListener('change', getQueryPreference);
    // eslint-disable-next-line
  }, []);

  document.body.style.backgroundColor = darkMode ? '#111' : '#eee';

  const theme = React.useMemo(() => {
    return createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      palette: {
        type: darkMode ? 'dark' : 'light',
        text: {
          primary: darkMode ? '#eee' : '#111',
          secondary: darkMode ? '#ddd' : '#222',
          disabled: 'rgba(0, 0, 0, 0.38)',
          hint: 'rgba(0, 0, 0, 0.38)',
        },
        common: {
          black: '#333',
          white: '#ccc',
        },
        primary: {
          light: darkMode ? '#fff' : '#000',
          main: darkMode ? '#eee' : '#111',
          dark: darkMode ? '#ccc' : '#333',
          contrastText: darkMode ? '#111' : '#eee',
        },
      },
    });
  }, [darkMode]);

  const onBrightnessChange = (brightnessMode) => {
    setBrightness(brightnessMode);
  };
  // const userContext = React.useContext(UserContext);
  // const { init } = userContext;
  // if (init) {
  // return <SplashPage prefersDarkMode={prefersDarkMode} />;
  // }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<SplashPage darkMode={darkMode} />}>
          <Switch>
            <Route
              key='landing'
              exact
              path={routes.LANDING_URI}
              render={(props) => (
                <LandingPage
                  {...props}
                  darkMode={darkMode}
                  brightness={brightness}
                  onBrightnessChange={onBrightnessChange}
                />
              )}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
