/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */
import React from 'react';

import { UserProvider } from 'UserContext';
import App from './App';

const Root = () => {
  const [initialLoad, setInitialLoad] = React.useState(false);

  return (
    <UserProvider>
      <App
        initialLoadProp={initialLoad}
        setInitialLoadProp={() => setInitialLoad(true)}
      />
    </UserProvider>
  );
};

export default Root;
