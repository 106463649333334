/**
 * Copyright 2020-2021 Ian Pedersen. All Rights Reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  gridContainer: {
    display: 'grid',
    width: 'calc(100% + 20px)',
    marginLeft: -10,
    gridTemplateColumns: 'auto auto 1fr',
  },
  gridColumn: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100% !important',
  },
  divider: {
    borderStyle: 'none',
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    width: 30,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    fill: theme.palette.primary.dark,
  },
  text: {
    fontSize: '1.0rem',
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  },
});

const NamedDivider = ({ classes, className, lineWidth, name, ...other }) => (
  <div {...other} className={ClassNames(classes.gridContainer, className)}>
    <div className={classes.gridColumn}>
      <hr size={lineWidth} className={classes.divider} />
    </div>
    <div className={classes.gridColumn}>
      <Typography
        variant='caption'
        display='block'
        align='center'
        className={classes.text}
      >
        {name}
      </Typography>
    </div>
    <div className={classes.gridColumn}>
      <hr
        size={lineWidth}
        className={ClassNames(classes.fullWidth, classes.divider)}
      />
    </div>
  </div>
);

NamedDivider.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  lineWidth: PropTypes.number,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

NamedDivider.defaultProps = {
  className: '',
  lineWidth: 4,
  name: '',
};

export default withStyles(styles)(NamedDivider);
