/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */

// Public Pages (permanent)
export const LANDING_URI = '/';
// export const TOS_URI = '/tos';
// export const PRIVACY_URI = '/privacy';
// export const CONTACTUS_URI = '/contactus';
export const PROFILE_URI = '/profile';
export const LOGIN_URI = '/login';
export const SIGNUP_URI = '/signup';
export const SETTINGS_URI = '/planup';
export const FORGOT_URI = '/forgot';
export const THANKYOU_URI = '/thankyou';
